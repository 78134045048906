import Cookie from 'js-cookie';

export default class AxiosAdapter {
  activeCallCount = 0;

  constructor(axios, store) {
    this.axios = axios;
    this.store = store;
  }

  get headers() {
    return {};
  }

  requestHandler(method, url) {
    // console.log(`AXIOS ${method} '${url}'...`);
  }

  responseHandler(method, url, response) {
    this.activeCallCount--;
    // console.log(`AXIOS ${method} RECEIVE '${url}' response`, response);
    return response;
  }
  errorHandler(method, url, error, bypassErrorDisplay=false) {
    this.activeCallCount--;
    const { status } = error.response || {};
    if (status === 401) {
      window.location.href = '/account/login';
      return;
    }

    // Here we try to handle all the possible scenarios for the types of error messages that
    // the backend might give us since our backend error returns are not solidified.
    if (!bypassErrorDisplay) {
      try {
        if (error.response.data.message) {
          this.store.commit("snackbar/error", error.response.data.message);
        } else {
          error.response.data.errors.forEach(err => {
            const errorString =  typeof err === 'string' ? err : err.message || err.error;

            if (errorString) {
              this.store.commit("snackbar/error", errorString);
            }
          });
        }
      } catch (e) {
        console.log(`AXIOS ${method} '${url}' error`, error);
        this.store.commit('snackbar/error', `Server Error for ${url}:` + error.toString());
      }
    }

    throw error;
  }

  GET(url , options = {}, bypassErrorDisplay=false) {
    this.requestHandler('GET', url);
    this.activeCallCount++;

    return this.axios.request({
        url,
        method: 'get',
        responseType: 'json',
        withCredentials: true,
        headers: this.headers,
        ...options
      })
      .then(this.responseHandler.bind(this, 'GET', url))
      .catch(error => {
        return this.errorHandler('GET', url, error, bypassErrorDisplay);
      }
    );
  }

  GET_BLOB(url , options = {}, bypassErrorDisplay=false) {
    this.requestHandler('GET', url);
    this.activeCallCount++;

    let headers = {};

    return this.axios.request({
        url,
        method: 'get',
        responseType: 'blob',
        withCredentials: true,
        headers: headers,
        ...options
      })
      .then(this.responseHandler.bind(this, 'GET', url))
      .catch(error => {
        return this.errorHandler('GET', url, error, bypassErrorDisplay);
      }
    );
  }

  POST(url , body, headers = {}, customErrorHandler = undefined) {
    this.requestHandler('POST', url);
    this.activeCallCount++;

    return this.axios.request({
        url,
        data: body,
        method: 'post',
        responseType: 'json',
        withCredentials: true,
        headers: { ...this.headers, ...headers }
      })
      .then(this.responseHandler.bind(this, 'POST', url))
      .catch(error => {
        if (customErrorHandler) {
          return customErrorHandler('POST', url, error);
        }

        return this.errorHandler('POST', url, error);
      });
  }

  DELETE(url , body, headers = {}, customErrorHandler = undefined) {
    this.requestHandler('DELETE', url);
    this.activeCallCount++;

    return this.axios.request({
        url,
        data: body,
        method: 'delete',
        responseType: 'json',
        withCredentials: true,
        headers: { ...this.headers, ...headers }
      })
      .then(this.responseHandler.bind(this, 'POST', url))
      .catch(error => {
        if (customErrorHandler) {
          return customErrorHandler('POST', url, error);
        }

        return this.errorHandler('POST', url, error);
      });
  }

  PUT(url , body) {
    this.requestHandler('PUT', url);
    this.activeCallCount++;

    return this.axios.request({
        url,
        data: body,
        method: 'put',
        responseType: 'json',
        withCredentials: true,
        headers: this.headers
      })
      .then(this.responseHandler.bind(this, 'PUT', url))
      .catch(this.errorHandler.bind(this, 'PUT', url));
  }

  PATCH(url , body) {
    this.requestHandler('PATCH', url);
    this.activeCallCount++;

    return this.axios.request({
        url,
        data: body,
        method: 'patch',
        responseType: 'json',
        withCredentials: true,
        headers: this.headers
      })
      .then(this.responseHandler.bind(this, 'PATCH', url))
      .catch(this.errorHandler.bind(this, 'PATCH', url));
  }
};