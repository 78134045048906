import { ACTION_TYPE_SITE_USER_EDIT } from "@/PortalConstants";
import { processSiteLoaded, processSiteDetailsLoaded } from "@/utils/siteUtil";
import gatewayUrlBuilder from '@/lib/GatewayUrlBuilder';

//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  settings: undefined, // https://localhost:8000/portal_admin/settings
  units: "metric",
  site: undefined,
  detailedSite: undefined,
  sites: [],
  myUsers: [],
  sitesById: {},
  selectedSite: undefined,
  usersInvalidated: false,
  sitesInvalidated: false,
  sessionExpirationDate: undefined,
  sessionExpired: false,
  siteUser: {},
  portalUser: null,
  allSitesUsers: [],
  selectedSiteUsers: [],
  dialogs: {
    addUser: {
      show: false,
      user: {
        isAdminFor: {},
        portalConfiguration: {
          twoFactorAuthentication: false
        },
        phoneNumber: "",
        phoneCountryCode: "",
        firstName: "",
        lastName: "",
        siteIds: ""
      }
    },
    editUser: {
      show: false,
      user: {
        id: undefined,
        isAdminFor: {},
        portalConfiguration: {
          twoFactorAuthentication: false
        },
        phoneNumber: "",
        phoneCountryCode: "",
        firstName: "",
        lastName: "",
        siteIdsWithPermissions: [],
        siteIdsWithoutPermissions: []
      }
    }
  },
  ui: {
    subheader: {
      show: true,
      showBreadcrumbs: true,
      controlsShown: "site-settings",
      showControls: true
    }
  },
  allSiteUserRoles: [],
  communicationPurposes: [],
  appointmentCancellationReasons: [],
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  setSessionExpirationDate: function (state, expirationDate) {
    state.sessionExpirationDate = expirationDate;
  },
  setSessionExpired (state, value) {
    state.sessionExpired = value;
  },
  setPortalSettings: function (state, settings) {
    state.settings = settings;
  },
  setUnitTypes: function (state, units) {
    state.units = units;
  },
  setSite: function (state, site) {
    state.site = site;
    if (state.site?.id !== state.detailedSite?.id) {
      state.detailedSite = undefined;
    }
  },
  setDetailedSite: function (state, detailedSite) {
    state.detailedSite = processSiteDetailsLoaded(detailedSite);
  },
  setSites: function (state, sites) {
    sites = sites.map(processSiteLoaded);

    state.sitesInvalidated = false;

    sites.forEach(site => (state.sitesById[site.id] = site));

    state.sites = sites;
  },
  setMyUsers: function (state, myUsers) {
    state.myUsers = myUsers;
    state.usersInvalidated = false;
  },
  selectSite: function (state, site) {
    state.selectedSite = site;
  },
  setSelectedSiteUsers: function (state, siteUsers) {
    state.selectedSiteUsers = siteUsers;
  },
  openDialog: function (state, dialogName) {
    state.dialogs[dialogName].show = true;
  },
  closeDialog: function (state, dialogName) {
    state.dialogs[dialogName].show = false;
  },
  closeAllDialogs: function (state) {
  },
  setEditUserDialogUser: function (state, { user, vueComponent }) {
    const isAdminFor = {};
    const isTwoFactorFor = {};

    user.site_users.forEach(su => (isAdminFor[su.site_id] = su.roles.find(role => role.code === "portal_site_admin")));

    state.dialogs[DIALOG_EDIT_USER].user = {
      id: user.id,
      accountConfirmed: user.account_confirmed,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: user.phone_number,
      phoneCountryCode: user.phone_country_code,
      isAdminFor,
      portalConfiguration: {
        twoFactorAuthentication: user.portal_configuration ? user.portal_configuration.two_factor_authentication : false
      },
      siteIdsWithPermissions: user.site_users
        .map(su => su.site_id)
        .filter(site_id => vueComponent.hasPermissionInSite(site_id, ACTION_TYPE_SITE_USER_EDIT)),
      siteIdsWithoutPermissions: user.site_users
        .map(su => su.site_id)
        .filter(site_id => !vueComponent.hasPermissionInSite(site_id, ACTION_TYPE_SITE_USER_EDIT))
    };
  },
  invalidateSites: function (state) {
    state.sitesInvalidated = true;
  },
  invalidateUsers: function (state) {
    state.usersInvalidated = true;
  },
  resetAddUserDialog: function (state) {
    state.dialogs[DIALOG_ADD_USER].user = {
      isAdminFor: {},
      portalConfiguration: {
        twoFactorAuthentication: false
      },
      phoneNumber: "",
      phoneCountryCode: "",
      firstName: "",
      lastName: "",
      siteIds: ""
    };
  },
  resetSites: function (state) {
    state.sites = [];
    state.sitesById = {};
  },
  resetSettings: function (state) {
    state.settings = undefined;
  },
  setPortalUser: function (state, portalUser) {
    state.portalUser = portalUser;
  },
  setAllSitesUsers: function (state, allSitesUsers) {
    state.allSitesUsers = allSitesUsers;
  },
  setAllSiteUserRoles: function (state, allSiteUserRoles) {
    state.allSiteUserRoles = allSiteUserRoles;
  },
  setCommunicationPurposes: function (state, communicationPurposes) {
    state.communicationPurposes = communicationPurposes;
  },
  setAppointmentCancellationReasons(state, reasons) {
    state.appointmentCancellationReasons = reasons;
  },
};

export const actions = {
  async fetchDetailedSite ({commit}, siteId) {
    const {data: detailedSite} = await this.$apiv2.getSiteById(siteId);
    commit('setDetailedSite', detailedSite);
    return detailedSite;
  },
  async fetchAllSiteUserRoles ({ commit }) {
    const allSiteUserRoles = await this.$apiv2.getCareTeamSiteUserRoles();
    commit('setAllSiteUserRoles', allSiteUserRoles);
  },
  async fetchCommunicationPurposes ({ commit }) {
    const communicationPurposes = await this.$apiv2.getCommunicationPurposes();
    const ehrReviewUpdate = communicationPurposes.find(purpose => purpose.id === 'EHR_REVIEW_UPDATE');
    const carePlanReviewUpdate = communicationPurposes.find(purpose => purpose.id === 'CARE_PLAN_REVIEW_UPDATE');
    if (ehrReviewUpdate) {
      ehrReviewUpdate.defaultValues = {
        duration: {value: 20},
        medium: {value: 'NONE', overwriteCurrentValue: true},
        direction: {value: 'NONE', overwriteCurrentValue: true},
        communicationResult: {value: 'PATIENT_UPDATED', overwriteCurrentValue: true},
        subject: {value: 'Patient Information Updates'},
      }
      ehrReviewUpdate.hasNoPatientInteraction = true;
      ehrReviewUpdate.disabledFields = ['medium', 'direction', 'communicationResult']
    }
    if (carePlanReviewUpdate) {
      carePlanReviewUpdate.defaultValues = {
        duration: {value: 20},
        medium: {value: 'NONE', overwriteCurrentValue: true},
        direction: {value: 'NONE', overwriteCurrentValue: true},
        communicationResult: {value: 'PATIENT_UPDATED', overwriteCurrentValue: true},
        subject: {value: 'Patient Information Updates'},
      }
      carePlanReviewUpdate.hasNoPatientInteraction = true;
      carePlanReviewUpdate.disabledFields = ['medium', 'direction', 'communicationResult']

    }
    commit('setCommunicationPurposes', communicationPurposes);
  },
  async assignPractitionersToSite ({commit, state}, { siteId, practitioners }) {
    const {data: site} = await this.$apiv2.assignPractitionersToSite({siteId, practitioners});
    if (state.site?.id == siteId) {
      const {data: detailedSite} = await this.$apiv2.getSiteById(site?.id);
      commit('setDetailedSite', detailedSite);
    }
    return site;
  },
  async fetchAppointmentCancellationReasons({ commit, state }) {
    if (state.appointmentCancellationReasons.length === 0) {
      const reasons = await this.$apiv2.getAppointmentCancellationReasons();
      commit('setAppointmentCancellationReasons', reasons);
    }
  },
  initializeStore ({ dispatch }) {
    dispatch('fetchAllSiteUserRoles');
    dispatch('fetchCommunicationPurposes');
  }
};

export const getters = {
  getCurrentSite (state) {
    return state.site;
  },
  userId: s => s.portalUser && s.portalUser.id,
  isStaff: s => s.portalUser && s.portalUser.is_staff,
  globalSiteRoles: s => {
    const globalSiteUser = s.portalUser ? s.portalUser.site_users?.find(su => su.site_user?.site_id === "57e54764-ae0a-45ed-a9c5-b3132dafe064") : null;
    return globalSiteUser?.site_user?.roles || [];
  },
  userHasGlobalRole: (_, getters) => (roleCode) => getters.globalSiteRoles.find(role => role.code === roleCode),
  currentSiteId: (_, getters) => getters.getCurrentSite && getters.getCurrentSite.id,
  getSiteByShortName: state => shortName => state.sites.find(site => site.short_name === shortName),
  sitesSortedByFullName: state => {
    const s = [...state.sites]
    return s.sort((a, b) => a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase()));
  },
  siteHierarchy: state => {
    const siteHierarchy = [];
    let site = state.site;
    while (site) {
      siteHierarchy.unshift({...site});
      site = site.parent_id && state.sites.find(s => s.id === site.parent_id);
    }
    return siteHierarchy;
  },
  siteUsersBySiteId: (state) => (siteId) => state.allSitesUsers
    .filter(user => user.siteUsers.some(siteUser => siteUser.site.id === siteId)),
  siteUsers: state => state.portalUser && state.portalUser.site_users.map(item => item.site_user),
  getSiteUserById: (_, getters) => id => getters.siteUsers.find(siteUser => siteUser.site_id === id),
  currentSiteUser: (_, getters) => getters.getSiteUserById(getters.currentSiteId),
  hasAnySiteWithFeatureValue: state => (feature, value) => state.sites.some(site => site.feature_values && site.feature_values[feature] === value),
  curSiteHasFeatureValue: (state) => (feature, value) => {
    const curDetailedSite = state.detailedSite;
    return curDetailedSite && curDetailedSite.feature_values && curDetailedSite.feature_values[feature] === value;
  },
  includeSidebarInLayout: (_, getters) => {
    return getters.hasAnySiteWithFeatureValue('portal_us_features', 'true') || getters.hasAnySiteWithFeatureValue('zoom_smart_integration_feature', 'true');
  },
  userHasAccessToSite: state => (siteId) => siteId && state.sites.some(siteWithPermission => siteWithPermission.id && siteWithPermission.id === siteId),
  userHasAccessToAtLeastOneSiteForProfile: (_, getters) => (profile) => profile?.siteProfiles?.some(sp => getters.userHasAccessToSite(sp.site?.id)),
  gatewayNewSiteUserUrl: (_, getters) => (siteId) => gatewayUrlBuilder.newSiteUser(getters.userId, siteId),
  gatewayNewPatientUrl: () => (siteId, practitionerNpi = null) => gatewayUrlBuilder.addProfile(siteId, practitionerNpi),
  isCareCalendarEnabled: (_, getters) => getters.hasUSSite && getters.isInternalUserForOneSite,
  isInternalUser: (_, getters) => getters.currentSiteUser && getters.currentSiteUser.roles.filter(e => ['portal_site_admin', 'admin', 'super_admin'].includes(e.code)).length > 0,
  isInternalUserForOneSite: (_, getters) => getters.siteUsers && getters.siteUsers.some(su => su.roles.filter(e => ['portal_site_admin', 'admin', 'super_admin'].includes(e.code)).length > 0),
  isUSFeature: s => s.detailedSite && s.detailedSite.feature_values && s.detailedSite.feature_values.portal_us_features === 'true',
  hasUSSite: s => s.sites.some(site => site.feature_values && site.feature_values.portal_us_features === 'true'),
  zoomEnabledForThisSite: s => s.detailedSite && s.detailedSite.feature_values && s.detailedSite.feature_values.zoom_smart_integration_feature === 'true',
  appointmentCancellationOptions: state => Object.entries(state.appointmentCancellationReasons).map(([value, label]) => ({ value, label })),
};
