import Vue from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { OWNER } from '@/components/layout/sidebar/sidebar-panels/sms/constants';

export const state = () => ({
  loading: false,
  error: null,
  messages: {},
  channels: [],
  assignedChannels: [],
  selectedChannelOwner: OWNER.ME,
  selectedViewInterval: null,
  userAssignedInterval: null,
});

const MESSAGE_BATCH_SIZE = 100;

export const mutations = {
  SET_LOADING (state, loading) {
    state.loading = loading;
  },
  SET_ERROR (state, error) {
    state.error = error;
  },
  SET_MESSAGES (state, messages) {
    state.messages = messages;
  },
  SET_CHANNELS (state, channels) {
    state.channels = channels;
  },
  SET_ASSIGNED_CHANNELS (state, channels) {
    state.assignedChannels = channels;
  },
  SET_MESSAGES_FOR_CHANNEL (state, { channelId, messages, hasEarlierMessages=state.messages[channelId]?.hasEarlierMessages }) {
    Vue.set(state.messages, channelId, {messages, hasEarlierMessages});
  },
  SET_SELECTED_CHANNEL_OWNER (state, owner) {
    state.selectedChannelOwner = owner;
  },
  SET_VIEW_INTERVALS (state, { viewInterval, assignedInterval }) {
    state.selectedViewInterval = viewInterval;
    state.userAssignedInterval = assignedInterval;
  },
  CLEAR_VIEW_INTERVALS (state) {
    clearInterval(state.selectedViewInterval);
    clearInterval(state.userAssignedInterval);
    state.selectedViewInterval = null;
    state.userAssignedInterval = null;
  },
};

export const actions = {
  initMessagingModule ({ commit, dispatch, state, getters }) {
    const viewInterval = setInterval(async () => {
      // Initially fetch just the 1 most recently updated channel. If there is a new update, fetch the rest.
      const result = await dispatch('fetchChannels', {
        ignoreViewSaving: true,
        ignoreAssignedSaving: true,
        overridePagination: { sortOrder: -1, sortField: 'lastModifiedDate', size: 1 }
      });
      const resultLastModifiedDate = result.length && result[0].lastModifiedDate;
      if (resultLastModifiedDate > getters.lastModifiedAmongViewChannels) {
        dispatch('fetchChannels')
      }
    }, 10000);
    const assignedInterval = setInterval(async () => {
      if (state.selectedChannelOwner !== OWNER.ME) {
        // Initially fetch just the 1 most recently updated assigned channel. If there is a new update, fetch the rest.
        const result = await dispatch('fetchChannels', {
          ignoreViewSaving: true,
          ignoreAssignedSaving: true,
          overrideFilters: { owner: OWNER.ME },
          overridePagination: { sortOrder: -1, sortField: 'lastModifiedDate', size: 1 }
        });
        const resultLastModifiedDate = result.length && result[0].lastModifiedDate;
        if (resultLastModifiedDate > getters.lastModifiedAmongAssignedChannels) {
          dispatch('fetchChannels', { ignoreViewSaving: true, overrideFilters: { owner: OWNER.ME } });
        };
      }
    }, 10000);
    commit('SET_VIEW_INTERVALS', { viewInterval, assignedInterval });
  },
  teardownMessagingModule ({ commit }) {
    commit('CLEAR_VIEW_INTERVALS');
  },
  changeSelectedChannelOwner ({ commit, dispatch }, owner) {
    commit('SET_SELECTED_CHANNEL_OWNER', owner);
    dispatch('fetchChannels');
  },
  async fetchChannels ({ commit, getters, state }, payload = {}) {
    commit('SET_LOADING', true);
    try {
      // Dynamically generate query parameters based on current state and any override filters provided in payload
      const queryParams = getters.channelsQueryParams(payload.overrideFilters, payload.overridePagination);

      const channels = await this.$apiv2.getMessageChannels(queryParams);
      channels.reverse();


      if (!payload.ignoreViewSaving) {
        commit('SET_CHANNELS', channels);
      }

      // Always store assigned channels in state when fetched
      if (!payload.ignoreAssignedSaving && (payload.overrideFilters?.owner === OWNER.ME || state.selectedChannelOwner === OWNER.ME)) {
        commit('SET_ASSIGNED_CHANNELS', channels);
      }

      commit('SET_ERROR', null);
      return channels;
    } catch (err) {
      commit('SET_ERROR', err);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchMessagesForChannel ( { state, commit, getters }, { channelId }) {
    if (!channelId) {
      throw new Error('Channel id required');
    }
    try {
      const existingMessages = getters.getMessagesByChannelId(channelId);
      const earliestCreatedDate = getters.earliestCreatedByChannelId(channelId);
      const {headers, data: latestMessages} = await this.$apiv2.getAllMessages(
        { 
          filters: {
            channelId: {
              value: channelId, 
              matchMode: FilterMatchMode.EQUALS
            }, 
            createdDate: {
              value: earliestCreatedDate,
              matchMode: FilterMatchMode.LESS_THAN
            }, 
          },
          sortOrder: -1,
          sortField: 'createdDate',
          size: MESSAGE_BATCH_SIZE,
        }
      )
      const messages = [...existingMessages, ...latestMessages];
      const hasEarlierMessages = headers['x-total-count'] > MESSAGE_BATCH_SIZE;
      commit('SET_MESSAGES_FOR_CHANNEL', { channelId, messages, hasEarlierMessages });
      commit('SET_ERROR', null);
      return latestMessages;
    } catch (err) {
      commit('SET_ERROR', { message: 'Error retrieving messages' });
    }
  },
  async fetchLatestMessagesForChannel ({ commit, getters }, { channelId }) {
    if (!channelId) {
      throw new Error('Channel id required');
    }
    try {
      const existingMessages = getters.getMessagesByChannelId(channelId);
      const lastModifiedDate = getters.lastUpdatedByChannelId(channelId);
      const {data: latestMessages} = await this.$apiv2.getAllMessages(
        { 
          filters: { 
            channelId: {
              value: channelId, 
              matchMode: FilterMatchMode.EQUALS,
            },
            lastModifiedDate: {
              value: lastModifiedDate,
              matchMode: FilterMatchMode.GREATER_THAN,
            }, 
          },
          sortOrder: -1,
          sortField: 'lastModifiedDate',
          size: MESSAGE_BATCH_SIZE 
        }
      )
      const messages = [...existingMessages];
      latestMessages.sort((a,b) => a.createdDate > b.createdDate ? 1 : -1);
      latestMessages.forEach(newMessage => {
        const existingMessageIndex = messages.findIndex(msg => msg.createdDate === newMessage.createdDate);
        if (existingMessageIndex !== -1) {
          messages.splice(existingMessageIndex, 1, newMessage);
        } else {
          messages.unshift(newMessage);
        }
      })
      commit('SET_MESSAGES_FOR_CHANNEL', { channelId, messages });
      commit('SET_ERROR', null);
      return latestMessages;
    } catch (err) {
      commit('SET_ERROR', { message: 'Error retrieving most recent messages' });
    }
  },
  async fetchChannelByPrincipal ({ state, commit }, principal) {
    try {
      const channels = await this.$apiv2.getMessageChannels(
        { filters: { 'principal': { matchMode: 'equals', value: principal } } })
      const channel = channels.length ? channels[0] : null;

      // Check if there are no assigned channels and a channel is found
      if (state.assignedChannels.length && channel) {
        // Find the channel in assignedChannels
        const index = state.assignedChannels.findIndex(c => c.id === channel.id);
        if (index !== -1) {
          const assignedChannelsCopy = JSON.parse(JSON.stringify((state.assignedChannels)))
          assignedChannelsCopy[index].unreadMessages = 0;
          commit('SET_ASSIGNED_CHANNELS', assignedChannelsCopy);
        }
      }
      commit('SET_ERROR', null);
      return channel
    } catch (err) {
      commit('SET_ERROR', { message: 'Error retrieving most recent message' });
    }
  },
  async sendMessage ({ commit, dispatch }, { principal, message }) {
    try {
      const sentMessage = await this.$apiv2.sendMessage(principal, message);
      if (!sentMessage) {
        throw new Error('No data returned from messaging service');
      }

      if (sentMessage) {
        dispatch('fetchChannels');
        await dispatch('fetchLatestMessagesForChannel',
          {
            channelId: sentMessage.channel?.id,
          }
        );
      }
      commit('SET_ERROR', null);
      return sentMessage;
    } catch (err) {
      commit('SET_ERROR', { message: 'Error sending message' });
    }
  },
};

export const getters = {
  getMessagesByChannelId: (state) => (channelId) => {
    return state.messages[channelId] && state.messages[channelId].messages || [];
  },
  lastUpdatedByChannelId: (_, getters) => (channelId) => {
    const channelMessages = getters.getMessagesByChannelId(channelId);
    return channelMessages.length ? channelMessages.reduce((acc, msg) => {
      return msg.lastModifiedDate > acc ? msg.lastModifiedDate : acc;
    }, channelMessages[0].lastModifiedDate) : null;
  },
  earliestCreatedByChannelId: (_, getters) => (channelId) => {
    const channelMessages = getters.getMessagesByChannelId(channelId);
    return channelMessages.length ? channelMessages[channelMessages.length-1].createdDate : null;
  },
  hasEarlierMessagesByChannelId: (state) => (channelId) => {
    return state.messages[channelId] && state.messages[channelId].hasEarlierMessages;
  },
  lastModifiedAmongViewChannels: (s) => s.channels.reduce((lastModified, channel) => {
    return (!lastModified || channel.lastModifiedDate > lastModified) ? channel.lastModifiedDate : lastModified;
  }, null),
  lastModifiedAmongAssignedChannels: (s) => s.assignedChannels.reduce((lastModified, channel) => {
    return (!lastModified || channel.lastModifiedDate > lastModified) ? channel.lastModifiedDate : lastModified;
  }, null),
  unreadChannelsCount: (s) => s.assignedChannels.filter(c => c.unreadMessages > 0).length,
  mostRecentMessage: (s) => s.channels && s.channels.length && s.channels[s.channels.length - 1].lastMessage,
  channelsQueryParams: (state, _, rootState, rootGetters) => (overrideFilters = {}, overridePagination = {}) => {
    let filters = {};
    let pagination = {
      sortOrder: -1,
      sortField: 'lastMessage.lastModifiedDate',
      size: 1000,
      ...overridePagination,
    }
    const ownerId = rootGetters['portal/userId'];

    // Override owner filter if specified in the payload
    if (overrideFilters.owner) {
      switch (overrideFilters.owner) {
        case OWNER.ME:
          filters.owner = { matchMode: FilterMatchMode.EQUALS, value: ownerId };
          break;
        case OWNER.OTHER:
          filters.owner = { matchMode: FilterMatchMode.NOT_EQUALS, value: ownerId };
          break;
        case OWNER.ALL:
          delete filters.owner;
          break;
      }
    }
    else {
      // Apply default or state-based owner filter logic
      switch (state.selectedChannelOwner) {
        case OWNER.ME:
          filters.owner = { matchMode: FilterMatchMode.EQUALS, value: ownerId };
          break;
        case OWNER.OTHER:
          filters.owner = { matchMode: FilterMatchMode.NOT_EQUALS, value: ownerId };
          break;
        case OWNER.ALL:
        default:
          // Potentially no filter applied
          break;
      }
    }
    return {
      ...pagination,
      filters,
    };
  },
};

