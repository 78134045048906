import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29d3d5d3 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _30c6f4b1 = () => interopDefault(import('../pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _3dc94e62 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _2e46fc9f = () => interopDefault(import('../pages/account/unconfirmed.vue' /* webpackChunkName: "pages/account/unconfirmed" */))
const _144147b9 = () => interopDefault(import('../pages/debug/api.vue' /* webpackChunkName: "pages/debug/api" */))
const _47047514 = () => interopDefault(import('../pages/debug/snackbar.vue' /* webpackChunkName: "pages/debug/snackbar" */))
const _cea04fb8 = () => interopDefault(import('../pages/errors/noaccess.vue' /* webpackChunkName: "pages/errors/noaccess" */))
const _ab0ad22c = () => interopDefault(import('../pages/settings/my-permissions.vue' /* webpackChunkName: "pages/settings/my-permissions" */))
const _229981e4 = () => interopDefault(import('../pages/spirometry/report.vue' /* webpackChunkName: "pages/spirometry/report" */))
const _44e03060 = () => interopDefault(import('../pages/account/password/forgot.vue' /* webpackChunkName: "pages/account/password/forgot" */))
const _467dae32 = () => interopDefault(import('../pages/account/password/reset.vue' /* webpackChunkName: "pages/account/password/reset" */))
const _79499fea = () => interopDefault(import('../pages/debug/mock/site.vue' /* webpackChunkName: "pages/debug/mock/site" */))
const _27dfc884 = () => interopDefault(import('../pages/debug/send/api.vue' /* webpackChunkName: "pages/debug/send/api" */))
const _c310e636 = () => interopDefault(import('../pages/debug/video/api.vue' /* webpackChunkName: "pages/debug/video/api" */))
const _31d771e4 = () => interopDefault(import('../pages/debug/video/invite.vue' /* webpackChunkName: "pages/debug/video/invite" */))
const _2e4a8a16 = () => interopDefault(import('../pages/settings/communication-preferences/_id.vue' /* webpackChunkName: "pages/settings/communication-preferences/_id" */))
const _24d2cc9a = () => interopDefault(import('../pages/settings/_siteslug.vue' /* webpackChunkName: "pages/settings/_siteslug" */))
const _62ebba3a = () => interopDefault(import('../pages/site/_slug/index.vue' /* webpackChunkName: "pages/site/_slug/index" */))
const _0c191908 = () => interopDefault(import('../pages/v/_participant_id.vue' /* webpackChunkName: "pages/v/_participant_id" */))
const _a9dd5488 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id" */))
const _7e60aa52 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/appointments.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/appointments" */))
const _15e42882 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/careSummary.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/careSummary" */))
const _421293b1 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/careTeam.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/careTeam" */))
const _e82f76ec = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/devices.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/devices" */))
const _74a57cfc = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/events.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/events" */))
const _36331d52 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/logs.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/logs" */))
const _84c82340 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/patientDetails.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/patientDetails" */))
const _1659980a = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/tasks.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/tasks" */))
const _5893d7d9 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/trends.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/trends" */))
const _a557d36c = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/asthmaReport/create.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/asthmaReport/create" */))
const _607f2fc5 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/asthmaReport/_asthma_report_id/index.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/asthmaReport/_asthma_report_id/index" */))
const _c4353232 = () => interopDefault(import('../pages/site/_slug/patient/_site_profile_id/asthmaReport/_asthma_report_id/edit.vue' /* webpackChunkName: "pages/site/_slug/patient/_site_profile_id/asthmaReport/_asthma_report_id/edit" */))
const _27eb63ad = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _43bdc58c = () => interopDefault(import('../pages/index/all-patients.vue' /* webpackChunkName: "pages/index/all-patients" */))
const _13285232 = () => interopDefault(import('../pages/index/dashboard.vue' /* webpackChunkName: "pages/index/dashboard" */))
const _1b2d1e21 = () => interopDefault(import('../pages/index/dashboard/appointments.vue' /* webpackChunkName: "pages/index/dashboard/appointments" */))
const _71c5afc6 = () => interopDefault(import('../pages/index/dashboard/events.vue' /* webpackChunkName: "pages/index/dashboard/events" */))
const _78dc7ff1 = () => interopDefault(import('../pages/index/dashboard/tasks.vue' /* webpackChunkName: "pages/index/dashboard/tasks" */))
const _395b68aa = () => interopDefault(import('../pages/index/sites.vue' /* webpackChunkName: "pages/index/sites" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _29d3d5d3,
    name: "404"
  }, {
    path: "/debug",
    component: _30c6f4b1,
    name: "debug"
  }, {
    path: "/account/login",
    component: _3dc94e62,
    name: "account-login"
  }, {
    path: "/account/unconfirmed",
    component: _2e46fc9f,
    name: "account-unconfirmed"
  }, {
    path: "/debug/api",
    component: _144147b9,
    name: "debug-api"
  }, {
    path: "/debug/snackbar",
    component: _47047514,
    name: "debug-snackbar"
  }, {
    path: "/errors/noaccess",
    component: _cea04fb8,
    name: "errors-noaccess"
  }, {
    path: "/settings/my-permissions",
    component: _ab0ad22c,
    name: "settings-my-permissions"
  }, {
    path: "/spirometry/report",
    component: _229981e4,
    name: "spirometry-report"
  }, {
    path: "/account/password/forgot",
    component: _44e03060,
    name: "account-password-forgot"
  }, {
    path: "/account/password/reset",
    component: _467dae32,
    name: "account-password-reset"
  }, {
    path: "/debug/mock/site",
    component: _79499fea,
    name: "debug-mock-site"
  }, {
    path: "/debug/send/api",
    component: _27dfc884,
    name: "debug-send-api"
  }, {
    path: "/debug/video/api",
    component: _c310e636,
    name: "debug-video-api"
  }, {
    path: "/debug/video/invite",
    component: _31d771e4,
    name: "debug-video-invite"
  }, {
    path: "/settings/communication-preferences/:id?",
    component: _2e4a8a16,
    name: "settings-communication-preferences-id"
  }, {
    path: "/settings/:siteslug?",
    component: _24d2cc9a,
    name: "settings-siteslug"
  }, {
    path: "/site/:slug",
    component: _62ebba3a,
    name: "site-slug"
  }, {
    path: "/v/:participant_id?",
    component: _0c191908,
    name: "v-participant_id"
  }, {
    path: "/site/:slug?/patient/:site_profile_id?",
    component: _a9dd5488,
    name: "site-slug-patient-site_profile_id",
    children: [{
      path: "appointments",
      component: _7e60aa52,
      name: "site-slug-patient-site_profile_id-appointments"
    }, {
      path: "careSummary",
      component: _15e42882,
      name: "site-slug-patient-site_profile_id-careSummary"
    }, {
      path: "careTeam",
      component: _421293b1,
      name: "site-slug-patient-site_profile_id-careTeam"
    }, {
      path: "devices",
      component: _e82f76ec,
      name: "site-slug-patient-site_profile_id-devices"
    }, {
      path: "events",
      component: _74a57cfc,
      name: "site-slug-patient-site_profile_id-events"
    }, {
      path: "logs",
      component: _36331d52,
      name: "site-slug-patient-site_profile_id-logs"
    }, {
      path: "patientDetails",
      component: _84c82340,
      name: "site-slug-patient-site_profile_id-patientDetails"
    }, {
      path: "tasks",
      component: _1659980a,
      name: "site-slug-patient-site_profile_id-tasks"
    }, {
      path: "trends",
      component: _5893d7d9,
      name: "site-slug-patient-site_profile_id-trends"
    }, {
      path: "asthmaReport/create",
      component: _a557d36c,
      name: "site-slug-patient-site_profile_id-asthmaReport-create"
    }, {
      path: "asthmaReport/:asthma_report_id",
      component: _607f2fc5,
      name: "site-slug-patient-site_profile_id-asthmaReport-asthma_report_id"
    }, {
      path: "asthmaReport/:asthma_report_id?/edit",
      component: _c4353232,
      name: "site-slug-patient-site_profile_id-asthmaReport-asthma_report_id-edit"
    }]
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index",
    children: [{
      path: "all-patients",
      component: _43bdc58c,
      name: "index-all-patients"
    }, {
      path: "dashboard",
      component: _13285232,
      name: "index-dashboard",
      children: [{
        path: "appointments",
        component: _1b2d1e21,
        name: "index-dashboard-appointments"
      }, {
        path: "events",
        component: _71c5afc6,
        name: "index-dashboard-events"
      }, {
        path: "tasks",
        component: _78dc7ff1,
        name: "index-dashboard-tasks"
      }]
    }, {
      path: "sites",
      component: _395b68aa,
      name: "index-sites"
    }]
  }, {
    path: "*",
    component: _29d3d5d3,
    name: "custom"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
